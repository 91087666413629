import { useEffect, useState } from "react";

type DarkModeSwitcherProps = {
  value: string;
  valueDarkMode: string;
  setCurrentValue: React.Dispatch<React.SetStateAction<string>>;
};

const DarkModeSwitcher = ({ value, valueDarkMode, setCurrentValue }: DarkModeSwitcherProps) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(document.documentElement.classList.contains("dark"));

  useEffect(() => {
    setCurrentValue(isDarkMode ? valueDarkMode : value);
  }, [isDarkMode, value, valueDarkMode, setCurrentValue]);

  useEffect(() => {
    const observer = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          const darkModeActive = document.documentElement.classList.contains("dark");
          setIsDarkMode(darkModeActive);
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
    });

    return () => observer.disconnect();
  }, []);
};

export default DarkModeSwitcher;
