import { useState } from "react";
import { TypeAnimation } from "react-type-animation";
import house from "../assets/NE Axo_red_DE.png";
import house_dark from "../assets/SW Axo_red_DE.png";
import DarkModeSwitcher from "../hooks/darkModeSwitcher";
import Hamburger from "./Hamburger";
import ThemeSwitcher from "./ThemeSwitcher";

const Header = () => {
  const [currentImage, setCurrentImage] = useState(house);
  DarkModeSwitcher({ value: house, valueDarkMode: house_dark, setCurrentValue: setCurrentImage });

  return (
    <div className="flex flex-col justify-center items-center h-screen text-center bg-lightGray dark:bg-gray-800 text-black dark:text-white transition-colors duration-1000">
      <div className="absolute top-4 right-4">
        <ThemeSwitcher />
      </div>
      <div className="absolute top-4 left-4 flex items-center">
        <Hamburger />
      </div>

      <h1 className="font-semibold text-2xl sm:text-3xl lg:text-4xl sm:pt-1 font-abc-gravity dark:font-minion-pro">
        <TypeAnimation
          sequence={["Wilkommen bei den STADTLÜÜD!"]}
          wrapper="span"
          speed={30}
          repeat={0}
          cursor={false}
        />
      </h1>
      <div className="text-base sm:text-lg lg:text-xl font-abc-gravity font-normal dark:font-minion-pro">
        <TypeAnimation
          sequence={[
            "Wir suchen noch Mitstreiter:innen für unser Projekt!",
            1000,
            "Interessiert?",
            1000,
            "Dann schreib uns!",
            1000,
          ]}
          wrapper="span"
          speed={50}
          repeat={Infinity}
        />
      </div>
      <a
        href={`mailto:info@stadtlueued.de`}
        className="bg-black hover:bg-gray-800 font-abc-gravity dark:bg-white dark:hover:bg-gray-200 text-white dark:text-black mb-10 p-3 lg:px-6 lg:py-3 mt-4 lg:m-8 text-sm sm:text-base lg:text-lg rounded inline-block transition-colors duration-1000"
        title="mailto:info@stadtlueued.de"
      >
        Contact us
      </a>
      <figure className="w-full flex justify-center p-4 lg:p-0">
        <img
          src={currentImage}
          alt="House"
          className="max-w-full dark:bg-lightGray sm:max-w-xl lg:max-w-2xl rounded-3xl transition-transform duration-1000 transform scale-100"
        />
      </figure>
    </div>
  );
};

export default Header;
