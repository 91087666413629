import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import house from "../../assets/aussen.jpg";
import arcade from "../../assets/laubengang.jpg";
import { getFileUrl } from "./Downloads";

const houseDescription = (
  <div className="relative">
    <figure className="float-left w-full md:w-1/3 mr-4 mb-4">
      <img src={house} alt="Das Stadtlüüd-Haus" className="rounded-lg shadow-lg" />
      <figcaption className="text-sm text-center mt-2">Das zukünftige Haus nach derzeitigem Planungsstand</figcaption>
    </figure>
    Das Stadtlüüd-Haus entsteht als moderner Neubau im Wilhelmsburger Rathausviertel. Der Baubeginn ist für 2026
    geplant, der Einzug soll 2028 erfolgen. Das Gebäude wird in einer grünen, verkehrsarmen und familienfreundlichen
    Umgebung errichtet.
    <br />
    <br />
    Der Bau erfolgt in nachhaltiger Holzbauweise und erfüllt die neuesten energetischen Standards. Besondere
    Gemeinschaftsbereiche wie eine Dachterrasse und ein Gemeinschaftsraum fördern das nachbarschaftliche Miteinander.
    <br />
    <br />
    <figure className="float-right w-full md:w-1/3 ml-4 mb-4">
      <img src={arcade} alt="Das Stadtlüüd-Haus" className="rounded-lg shadow-lg" />
      <figcaption className="text-sm text-center mt-2">Blick am Laubengang</figcaption>
    </figure>
    Die barrierefreien und nach Bedarf rollstuhlgerechten Wohnungen sind zwischen 50 m² und 90 m² groß. Die
    Wohnungsgröße richtet sich nach den Vorgaben der IFB-Förderrichtlinien und hängt von der Haushaltsgröße ab (siehe
    Tabelle). Jede Wohnung verfügt über einen großzügigen Balkon oder eine Terrasse. Ab dem 1. Obergeschoss werden die
    Wohnungen über einen Laubengang erschlossen.
    <br />
    <br />
    Das Haus wird von uns als Genossenschaft verwaltet und gewährleistet den Bewohnenden eine langfristige, sichere
    Wohnperspektive mit transparenten und nur geringfügigen Mietanpassungen. Einen umfassenden Einblick in den aktuellen
    Planungsstand bietet diese{" "}
    <a
      href={getFileUrl("241206_planungsstand.pdf")}
      target="_blank"
      rel="noopener noreferrer"
      className="underline decoration-transparent transition duration-300 ease-in-out hover:decoration-inherit hover:text-blue-500 whitespace-pre"
    >
      Präsentation
      <OpenInNewIcon />
    </a>
    .
  </div>
);

const houseTable = (
  <div className="w-full overflow-x-auto mt-6">
    <table className="table-auto border-collapse border border-gray-300 w-full">
      <thead>
        <tr className="bg-orange-200 dark:bg-orange-700 text-xs sm:text-sm md:text-lg">
          <th className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">Personen im Haushalt</th>
          <th className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">Zulässige Wohnfläche</th>
        </tr>
      </thead>
      <tbody>
        <tr className="bg-gray-100 dark:bg-gray-700 text-xs sm:text-sm md:text-lg">
          <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">1</td>
          <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">von 30 m² bis 50 m²</td>
        </tr>
        <tr className="text-xs sm:text-sm md:text-lg">
          <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">2</td>
          <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">von 50 m² bis 60 m²</td>
        </tr>
        <tr className="bg-gray-100 dark:bg-gray-700 text-xs sm:text-sm md:text-lg">
          <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">3</td>
          <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">von 65 m² bis 75 m²</td>
        </tr>
        <tr className="text-xs sm:text-sm md:text-lg">
          <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">4</td>
          <td className="border border-gray-300 px-2 sm:px-4 py-2 dark:border-gray-600">von 75 m² bis 90 m²</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export const House = {
  className: "house",
  titlePrimary: "Unser Haus",
  titleSecondary: "Das Stadtlüüd-Haus",
  content: (
    <>
      {houseDescription}
      {houseTable}
    </>
  ),
};
