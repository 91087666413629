import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="w-full overflow-hidden mx-auto px-10 py-4 flex flex-col items-center bg-white dark:bg-gray-800 text-black dark:text-white">
      <div className="flex space-x-4 mt-8 items-center">
        <a href="mailto:info@stadtlueued.de">
          <span className={"h-7 w-7 icon-[mdi--email]"}></span>
        </a>
      </div>

      <div className="py-4 text-center">
        <small>
          Copyright &copy; Stadtlüüd GbR |{" "}
          <Link className="hover:underline" to="/impressum" target="_blank" rel="noopener noreferrer">
            Impressum & Datenschutz
          </Link>
        </small>
      </div>
    </footer>
  );
};
export default Footer;
