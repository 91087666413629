type TextBlockWhiteProps = {
  titlePrimary: string;
  titleSecondary: string;
  text: JSX.Element;
};

const TextBlockWhite: React.FC<TextBlockWhiteProps> = ({ titlePrimary, titleSecondary, text }) => {
  return (
    <section className="w-full min-h-screen font-minion-pro flex flex-col justify-center items-center p-4 sm:p-6 lg:p-8 bg-deepRed dark:bg-gray-600 text-black dark:text-white">
      <p className="text-2xl sm:text-3xl lg:text-4xl font-bold border-b-4 border-pink-600 dark:border-pink-400">
        {titlePrimary}
      </p>
      <div className="w-full pt-4 sm:pt-6 lg:pt-8 flex justify-center">
        <article className="w-full max-w-3xl sm:max-w-4xl md:max-w-5xl lg:max-w-6xl p-4 sm:p-6 lg:p-8 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-lg shadow-2xl flex flex-col items-center justify-center text-center">
          <h2 className="mb-2 sm:mb-3 lg:mb-4 text-xl sm:text-xl lg:text-2xl font-bold text-gray-900 dark:text-gray-100">
            {titleSecondary}
          </h2>
          <p className="text-base sm:text-lg lg:text-xl text-gray-900 dark:text-gray-100">{text}</p>
        </article>
      </div>
    </section>
  );
};

export default TextBlockWhite;
